.current-order {
    flex: 2;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.banner {
    flex: 1;
    background-color: #3f3d3e;
    /* Tomato color */
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 15px;
}

.logo-container img {
    width: 150px;
    /* Adjust the size of the circular container */
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    /* Optional: Background color for the logo container */
}

.facebook-link {
    position: absolute;
    right: 20px;
}

.order-system {
    display: flex;
    height: 100vh;
}

.order-list {
    flex: 1;
    background-color: #3f3d3e;
    color: white;
    padding: 20px;
    box-sizing: border-box;
}

.order-list h2 {
    font-size: 2vw;
    margin-bottom: 20px;
}

.order-sublist ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 6vw;
}

.order-list li {
    margin-bottom: 10px;
}

.order-sublist {
    display: flex;
    text-align: center;
    justify-content: center;
}

.order-number {
    flex: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16vw
}